<template>
  <b-card-code title="Créer un nouveau étudiant">
    <b-card-text>
      <span>Veuillez saisir les coordonnées de l'inscription </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Identité"
              label-for="Identité"
            >
              <validation-provider
                #default="{ errors }"
                name="Identité"
                rules="required"
              >
                <b-form-input
                  v-model="students.id"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Identité"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nom et Prénom de l'étudiant"
              label-for="Nom et Prénom de l'étudiant"
            >
              <validation-provider
                #default="{ errors }"
                name="Nom et Prénom de l'étudiant"
                rules="required"
              >
                <b-form-input
                  v-model="students.username"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nom et Prénom de l'étudiant"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Parcour"
              label-for="Parcour"
            >
              <validation-provider
                #default="{ errors }"
                name="parcour"
                rules="required"
              >

                <v-select
                  v-model="inscrit.parcour"
                  :clearable="false"
                  :options="parcours"
                  label="title"
                  placeholder="parcour"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Group"
              label-for="Group"
            >
              <validation-provider
                #default="{ errors }"
                name="group"
                rules="required"
              >
                <v-select
                  v-model="inscrit.group"
                  :clearable="false"
                  :options="groupes"
                  label="group_code"
                  placeholder="group"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Niveau"
              label-for="Niveau"
            >
              <validation-provider
                #default="{ errors }"
                name="Niveau"
                rules="required"
              >
                <v-select
                  v-model="inscrit.level"
                  :clearable="false"
                  :options="level"
                  label="group_code"
                  placeholder="Niveau"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <h5>Redoublant</h5>
              <b-form-checkbox
                v-model="inscrit.redouble"
                name="check-button"
                switch
                inline
                @click="inscrit.redouble = true"
              >
                Redoublant
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addInscrit(); editStudent();"
            >
              Ajouter
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import storeAuth from '@/store/store'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormCheckbox,
  },
  data() {
    return {
      password: '',
      repositories: [],
      inscrit: {
        id: '',
        academic_year_id: '',
        student_id: '',
        student_is_inscrit: true,
        parcour: '',
        group: '',
        redouble: '',
        level: '',
        is_inscrit: true,
      },
      students: [],
      parcours: [],
      groups: [],
      level: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      required,
    }
  },
  computed: {
    groupes() {
      // eslint-disable-next-line eqeqeq
      return this.groups.filter(item => item.parcour.id == this.inscrit.parcour.id)
      // return this.groups.filter(item => item.parcours.id == this.inscrit.parcour.id)
    },
  },
  created() {
    this.id = this.$route.params.id
    this.inscrit.student_id = this.$route.params.id
    this.inscrit.academic_year_id = storeAuth.state.currentAcademicYear.id
    this.getGroups()
    this.getStudent()
    this.getParcours()
  },
  methods: {
    async getStudent() {
      this.load = 'true'
      const { id } = this.$route.params
      const response = await axios.get(`/api/authentication/users/detail/${id}`)
      this.students = response.data
      this.load = 'false'
    },
    async getParcours() {
      this.load = 'true'
      const response = await axios.get('/api/parcours/')
      this.parcours = response.data
      this.load = 'false'
    },
    async getGroups() {
      this.load = 'true'
      const response = await axios.get('/api/groups/')
      this.groups = response.data
      this.load = 'false'
    },

    async addInscrit() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios
              .post(
                '/api/inscriptions/create/',
                {
                  student: this.inscrit.student_id,
                  parcour: this.inscrit.parcour.id,
                  group: this.inscrit.group.id,
                  academic_year: this.inscrit.academic_year_id,
                  redouble: this.inscrit.redouble,
                  level: this.inscrit.level,
                  is_inscrit: this.inscrit.is_inscrit,
                },
              )
            this.$router.push('/inscriptions')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Inscriptions ajouté avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },
    async editStudent() {
      this.$refs.simpleRules.validate().then(async success => {
        const { id } = this.$route.params
        if (success) {
          try {
            await axios
              .put(
                `/api/authentication/users/update/${id}/`,
                {
                  email: this.students.email,
                  username: this.students.username,
                  is_inscrit: true,
                },
              )
            this.$router.push('/inscriptions')
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    formatPhone(e) {
      return String(e).substring(0, 8)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
