var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Créer un nouveau étudiant"}},[_c('b-card-text',[_c('span',[_vm._v("Veuillez saisir les coordonnées de l'inscription ")])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Identité","label-for":"Identité"}},[_c('validation-provider',{attrs:{"name":"Identité","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Identité","disabled":""},model:{value:(_vm.students.id),callback:function ($$v) {_vm.$set(_vm.students, "id", $$v)},expression:"students.id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nom et Prénom de l'étudiant","label-for":"Nom et Prénom de l'étudiant"}},[_c('validation-provider',{attrs:{"name":"Nom et Prénom de l'étudiant","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Nom et Prénom de l'étudiant","disabled":""},model:{value:(_vm.students.username),callback:function ($$v) {_vm.$set(_vm.students, "username", $$v)},expression:"students.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Parcour","label-for":"Parcour"}},[_c('validation-provider',{attrs:{"name":"parcour","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"options":_vm.parcours,"label":"title","placeholder":"parcour"},model:{value:(_vm.inscrit.parcour),callback:function ($$v) {_vm.$set(_vm.inscrit, "parcour", $$v)},expression:"inscrit.parcour"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Group","label-for":"Group"}},[_c('validation-provider',{attrs:{"name":"group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"options":_vm.groupes,"label":"group_code","placeholder":"group"},model:{value:(_vm.inscrit.group),callback:function ($$v) {_vm.$set(_vm.inscrit, "group", $$v)},expression:"inscrit.group"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Niveau","label-for":"Niveau"}},[_c('validation-provider',{attrs:{"name":"Niveau","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"options":_vm.level,"label":"group_code","placeholder":"Niveau"},model:{value:(_vm.inscrit.level),callback:function ($$v) {_vm.$set(_vm.inscrit, "level", $$v)},expression:"inscrit.level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('h5',[_vm._v("Redoublant")]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},on:{"click":function($event){_vm.inscrit.redouble = true}},model:{value:(_vm.inscrit.redouble),callback:function ($$v) {_vm.$set(_vm.inscrit, "redouble", $$v)},expression:"inscrit.redouble"}},[_vm._v(" Redoublant ")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();_vm.addInscrit(); _vm.editStudent();}}},[_vm._v(" Ajouter ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset"}},[_vm._v(" Réinitialiser ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }